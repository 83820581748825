import React from 'react';
import './BBContainer.css'
import BlueBox from './BlueBox';


function BBCont() {
    return ( 
        <> 
        <div className="bbheading" id="bbheading">
            <h2>HOW IT WORKS</h2>
        </div>
        <div className="bbcont--wrapper">
            <div className="bbcont">
                <BlueBox text="HAVE A CHAT WITH US"/>
                <BlueBox text="WE DESIGN THE PRODUCT"/>
                <BlueBox text="YOU LAUNCH YOUR PRODUCT"/>
            </div>
        </div>
        </>
     );
}
 
export default BBCont;