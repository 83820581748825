import React from 'react'
import PageHeading from '../PageHeading'
import '../about.css'
import ButtonMain from '../Button'
import { NavHashLink } from 'react-router-hash-link'



function about() {

    return (
        <>
            <PageHeading headtext='ABOUT'/>
            <div className="about">
                    <div className="about--container">
                        <div className="about--left">
                            <p>Innobi Design exists to empower people and companies to be their best through good design. We make sure people love you for your products. <br />Authentically designed goods from Melbourne, Australia.</p>
                            <NavHashLink smooth className="scrolltocontact-nav" to="/#scrollstop">
                                <ButtonMain text='Contact Us'/>
                            </NavHashLink>
                        </div>
                        <div className="about--right">
                        </div>
                    </div>
                </div>
        </>
    )
}

export default about
