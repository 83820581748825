import React from 'react'
import './Calltoaction_Home.css'

const Calltoaction_Home = () => {
    return (
        <>
        <div className='calltoaction--main'>
            <div className="calltoaction--wrapper">
                <h2 className="h2brown">HAVE A FREE CHAT WITH US TO SEE HOW WE CAN CREATE THE PRODUCT YOUR CUSTOMERS WILL LOVE</h2>
            </div>
        </div>
        
        </>
    )
}

export default Calltoaction_Home
