import React from 'react';
import './BlueBox.css';

function BlueBox (props) {
    return ( 
    <>
        <div className="bbox">
            <div className="bbox--container">
                <h3 className="h3white">{props.text}</h3>
            </div>
        </div>
    </>
     );
}

export default BlueBox;