import React, { useState } from 'react';
import {ReactComponent as ReactLogo} from './innobi-logo.svg';
import {Link} from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link'
import './Navbar.css'


function Navbar() {
    const [click, setClick] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


    const handleScroll = () => {
        if(window.scrollY > 30) {
            setNavbar(true);
        } else if (window.scrollY < 1) {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    return ( 
        <>
            <nav className={navbar ? 'navbar active' : 'navbar'}>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <ReactLogo />
                    </Link>
                   <div className='menu-icon' onClick={handleClick}>
                       <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                   </div>
                   <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                       <li className='nav-item'>
                           <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
                                PROJECTS
                           </Link>
                       </li>
                       <li className='nav-item'>
                           <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                ABOUT
                           </Link>
                       </li>
                       <li className='nav-item'>
                           <NavHashLink smooth className="scrolltocontact-nav" to="/#scrollstop" onClick={closeMobileMenu}>
                                CONTACT
                           </NavHashLink>
                       </li>
                   </ul>
                </div>
            </nav>
        </>
     );
}
 
export default Navbar;