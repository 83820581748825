import React from 'react';
import './RowTwo.css';
import {Link} from 'react-router-dom'

function RowTwo() {
    return ( 
    <>
        <div className="rowtwo--container">
            <div className="rowtwo--left">
            </div>
            <Link className='vproj-link' to='/projects'>
                <div className="rowtwo--right">
                    <div className="rowtwo--right--container">
                        <h2 className="vproj">VIEW PROJECTS</h2>
                    </div>
                </div>
            </Link>
        </div>
    </>
     );
}
 
export default RowTwo;