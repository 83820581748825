import React from 'react';
import './Button.css'

function ButtonMain(props) {
    return ( 
        <>
            <button className="defaultbtn">{props.text}</button>
        </>
     );
}
 
export default ButtonMain;