import React from 'react'
import './PageHeading.css'

const PageHeading = (props) => {
    return (
        <>
            <div className="pghead">
                <div className="pghead--container">
                    <h1 className='pghead--text'>{props.headtext}</h1>
                </div>
            </div>
        </>
    )
}

export default PageHeading
