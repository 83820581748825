import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import CookieConsent from 'react-cookie-consent';
import {hotjar} from 'react-hotjar'
import Navbar from './components/Navbar';
import home from './components/pages/home';
import projects from './components/pages/projects';
import about from './components/pages/about'
import ScrollTop from './components/ScrollTop';
import Footer from './components/Footer'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-188253021-2', {
  titleCase: false,
}
);
ReactGA.pageview(window.location.pathname + window.location.search);

hotjar.initialize(2357096, 6);

function App() {

  
  return (
  <>
    <Router>
      <ScrollTop />
      <Navbar />
      <CookieConsent 
        disableStyles={true}
        containerClasses="cookie--container"
        location='bottom'
        contentClasses='cookie--text'
        buttonClasses='cookie--button'
        buttonWrapperClasses='cookie--btn--wrapper'
        buttonText='Ok Great!'>We use cookies to enhance the website experience for our users. If you would like more information please read our <a href='https://www.privacypolicygenerator.info/live.php?token=quRL1gt6YaHpylnCThYC6gmcfEfROUP2' target="_blank" rel="noreferrer" className='privpolicy'>privacy policy</a>. Clicking 'Ok Great!' means you accept our usage of cookies on our website. </CookieConsent>
        
      <Switch>
        <Route path='/' exact component={home}/>
      </Switch>
      <Route path='/projects' exact component={projects}/>
      <Route path='/about' exact component={about}/>
      <Footer />
    </Router>
  </>
  );
}

export default App;
