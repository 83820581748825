import React from 'react';
import '../../App.css';
import AboveFold from '../AboveFold';
import BBCont from '../BBContainer';
import RowTwo from '../RowTwo';
import RowTxtLeft from '../Rowtxtleft';
import RowTxtRight from '../Rowtxtright';
import ThinkGuy from '../images/think_guy.png';
import MedalGraphic from '../images/medal-pic.png'
import ContactSection from '../ContactSection';
import CallToActionHome from '../Calltoaction_Home';



function home() {
    return (
    <>
        <AboveFold />
        <RowTwo />
        <RowTxtLeft 
        texth2="DON'T KNOW WHERE TO START?" 
        textp="Designing the right product for people can be challenging. That's why we're here to guide you through the process. " imgsrc={ThinkGuy}
        />
        <RowTxtRight />
        <RowTxtLeft 
        texth2="QUALITY MATTERS"
        textp="At Innobi Design we work with people who value quality. If you want your customers to have the best, have a chat with us today." id="medal" idcont="medal--container" imgsrc={MedalGraphic}
        />
        <BBCont />
        <CallToActionHome />
        <ContactSection />
    </>
     );
}
export default home;

