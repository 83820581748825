import React from 'react';
import './Rowtxtright.css';
import Image from './images/happy_guy.png';



function RowTxtRight() {
    return ( 
        <>
        <div className="RowTxtRight">
            <div className="RowTxtRight--container">
                <div className="RowTxtRight--Left">
                    <img className="img-2" src={Image} alt="img-2"/>
                </div>
                <div className="RowTxtRight--Right">
                    <h2 className="h2--dark">MODERN INNOVATION</h2>
                    <p>People's expectations are no longer the same. Innovation is not a luxury anymore, but a necessity. Innobi Design is here to provide the most creative solutions for today.</p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default RowTxtRight;