import React from 'react';
import './Footer.css';
import {ReactComponent as LogoFacebook} from './images/icon-facebook.svg';
import {ReactComponent as LogoInstagram} from './images/icon-instagram.svg';
import {ReactComponent as LogoLinkedIn} from './images/icon-linkedin.svg';
import {ReactComponent as ReactLogo2} from './innobi-logo-2.svg';

function Footer(props) {
    return (
        <div className='footer' >
            <div className="footer--container">
                <div className="footer--left">
                    <ReactLogo2 className='reactlogo--footer'/>
                </div>
                <div className="footer--right">
                    <div className="socialicons">
                        <a href='https://www.facebook.com/innobidesign' target="_blank" rel="noreferrer">
                            <LogoFacebook />
                        </a>
                        <a href='https://www.instagram.com/innobidesign/' target="_blank" rel="noreferrer">
                            <LogoInstagram />
                        </a>
                        <a href='https://www.linkedin.com/company/innobidesign' target="_blank" rel="noreferrer">
                            <LogoLinkedIn />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
