import React from 'react';
import './AboveFold.css';

function AboveFold() {
    return (
        <div className="herophoto">
            <div className="herophoto-container">
                <h1 className="herotext">WE HELP YOU <br />CREATE THINGS <br /> PEOPLE LOVE</h1>
            </div>
        </div>
     );
}
 
export default AboveFold;