import React from 'react'
import './PTContainer.css'
import ProjectTile from './ProjectTile'
import Jola_crop from './images/projimg_Jola_crop.png';
import Mez from './images/projimg_Mez.png';
import Sketch from './images/projimg_Sketch.png';
import Baus from './images/projimg_Baus.png';
import ProdJola_1 from './images/prodimg-jola-1.png'
import ProdMez_1 from './images/prodimg-mez-1.png'
import ProdBaus_1 from './images/prodimg-baus-1.png'
import ProdSketch_1 from './images/prodimg-sketch-1.png'

const PTContainer = () => {
    return (
        <>  
           
            <div className="ptcont">
                <div className="ptcont--container">
                    <ProjectTile projtitle='Jola' bkgimg={Jola_crop} prodimg={ProdJola_1} projinfo="Jola is the modern suitcase. It's a reflection of the possibilities of reducing the clutter from your life and travel experience. The striking professional and sharp personality of the suitcase reflects the utilitarian roots of its modular design. Its organisation features help to restore sanity to the packing process. It ensures that time is spent on the most important experiences of travel rather than searching for clothes or items."/>
                    <ProjectTile projtitle='Mez' bkgimg={Mez} prodimg={ProdMez_1} projinfo="Animals deserve exceptional treatment. Mez is the evolution of veterinary surgery, providing both animals and surgeons with the assurance of a safe and effective experience. The Mez surgery table assures the highest levels of hygiene and usability to ensure the experience is comfortable while minimising rates of complications. The integration of dental and heating components ensure a significant hazard reduction in the operating environment."/>
                    <ProjectTile projtitle='Baus' bkgimg={Baus} prodimg={ProdBaus_1} projinfo="Baus is a premium cold-press juicer for the home. Cold pressing retains most of the nutrients from the juicing processes and therefore is considered healthier. This design features a removable glass container for the waste enclosed in a premium stainless steel body. Amid the functionality of the juicer, great attention has been given to the styling of the design for a truly premium experience."/>
                    <ProjectTile projtitle='Sketch' bkgimg={Sketch} prodimg={ProdSketch_1} projinfo='Digital product design sketches.'/>
                </div>
            </div>
        </>
    )
}

export default PTContainer
