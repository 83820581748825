import React from 'react'
import LottieSuccess from './LottieSuccess'
import './FormSuccess.css'

const FormSuccess = () => {
    return (
        <>
            <div className="formsuccess">
                <div className="formsuccess--container">
                    <div className="formsuccess--left">
                        <LottieSuccess />
                    </div>
                    <div className="formsuccess--right">
                        <p>Thank you for submitting. We will be in contact with you shortly.</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FormSuccess
