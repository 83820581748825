import React, { useState } from 'react';
import './ContactSection.css';
import {ReactComponent as IcEmail} from './images/icon-email.svg';
import {ReactComponent as IcPhone} from './images/icon-phone.svg';
import FormSuccess from './FormSuccess'



function ContactSection () {
    
    /*function onChange(value) {
        console.log("Captcha value:", value);
      }
    */


    const [isSubmitted, setIsSubmitted] = useState(false);


    function handleSubmit(event){
        event.preventDefault();
        setIsSubmitted(true);
    }


    return (

        <>
        <div className="scrollstop" id='scrollstop'></div>
        <div className="formmain" id="formMain" >
            <div className="form--container" id='form--container'>
                <div className="formleft">
                    <h2 className="formheading">LET'S HAVE A CHAT!</h2>
                    <p className='forminfop'>You can contact us by filling out the form to the right. Otherwise, please use the details below.</p>
                    <div className="contactinfo">
                        <div className="contactinfo--container">
                            <div className="cont-info-container">
                                <IcEmail className='icon--contact'/>
                                <p className='continfop'>info@innobidesign.com</p>
                            </div>
                            <div className="cont-info-container">
                                <IcPhone className='icon--contact'/>
                                <p className='continfop'>+614 257 256 46</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formright">
                    <form className="contactform" onSubmit={handleSubmit}>
                        <label>Name</label>
                            <input type='text' name='name' className='form-input' ></input>
                        <label>Email</label>
                            <input type='email' name='email' className='form-input'/>
                        <label>Phone</label>
                            <input type='tel' name='phone' className='form-input'/>
                        <label>Message</label>
                            <textarea name='message' id='form-input'/>                        
                        <button type='submit' className="submitbtn" >Submit</button>

                        {isSubmitted && <FormSuccess />}

                        {/*<Recaptcha 
                        sitekey='6LdhQp4aAAAAANj0zMudvtz-vrZRoZXyGbJni13I'
                        render='explicit'
                        onChange={onChange}
                        theme='light'
                        />*/}
                    </form>
                    
                </div>
            </div>    
        </div>
        </>
    )
}

export default ContactSection
