import React from 'react'
import ReactDOM from 'react-dom'
import './Modal.css'
import CloseIcon from './images/cancelbtn-brown.png'
import {createGlobalStyle} from 'styled-components'

const portalRoot = document.getElementById("portal-root");

const ScrollDisabler = createGlobalStyle`
    body {
        overflow-y:hidden;
    }
`;

function Modal ({ isOpen, close, children }, props) {

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <>
            <div className="modal">
                <div className="modal--container">
                    {children}
                    <img className='closeicon' src={CloseIcon} alt="" onClick={close}/>

                </div>
            </div>
            <ScrollDisabler />


        </>,
        portalRoot
    )
}

export default Modal
