import React from 'react';
import ButtonMain from './Button';
import './Rowtxtleft.css';
import {Link} from 'react-scroll'




function RowTxtLeft(props) {


    return ( 
        <>
        <div className="RowTxtLeft">
            <div className="RowTxtLeft--container">
                <div className="RowTxtLeft--Left">
                    <h2 className="h2--dark">{props.texth2}</h2>
                    <p>{props.textp}</p>
                    <Link className="scrolltocontact" to="scrollstop" smooth={true} duration="800" >
                        <ButtonMain text="Contact Us"/>
                    </Link>
                </div>
                <div className="RowTxtLeft--Right" id={props.idcont}>
                    <img className="img-1" src={props.imgsrc} id={props.id} alt="img-1"/>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default RowTxtLeft;