import React from 'react';
import '../../App.css';
import PageHeading from '../PageHeading';
import PTContainer from '../PTContainer';

function projects() {
    return (
    <>  
        <PageHeading headtext='PROJECTS'/>
        <PTContainer />
    </>
    )
};

export default projects
