import React, {useState} from 'react'
import Modal from './Modal';
import './ProjectTile.css'


function ProjectTile(props) {

    const [isShown, setIsShown] = useState(false);
    const [isOpen, setOpen] = useState(false);
    console.log("isOpen", isOpen);

    return (
        <>
            <div className="tile">
                <div className="tile--container" 
                style={{backgroundImage: `url(${props.bkgimg})`}} 
                onMouseEnter={() => setIsShown(true)} 
                onMouseLeave={() => setIsShown(false)}
                onClick={() => setOpen(true)}
                >
                    {isShown && (
                    <div className="tileh2--container">
                        <h3 className="tileh2">{props.projtitle}</h3>
                    </div>
                )}
                
                </div>
                

                <Modal isOpen={isOpen} close={() => setOpen(false)}>
                    <div className="modal--container--inner">
                    <div className="modal--left">
                        <div className="prodimg" style={{backgroundImage: `url(${props.prodimg})`}}></div>
                    </div>
                    <div className="modal--right">
                        <div className="modal--right--inner">
                        <h1 id='modalh1'>{props.projtitle}</h1>
                        <p id='modalp'>{props.projinfo}</p>
                    </div>
                    </div>
                    </div>
                </Modal>
                        
                    

                
            </div>
        </>
    )
}

export default ProjectTile
